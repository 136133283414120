import React, { Suspense, useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Redirect } from 'react-router';
import Navbar from './container/NavBar';
import FavouriteManager from './container/FavouriteManager';
import Footer from './component/Footer';
import Loader from './component/Loader';
import { PopupControl } from 'component/PopupControl/PopupControl';

const HomePage = React.lazy(() => import('./container/Home'));
const AboutPage = React.lazy(() => import('./container/About'));
const ThingsToDoPage = React.lazy(() => import('./container/ThingsToDo'));
const SpecialsPage = React.lazy(() => import('./container/Specials'));
const EventsPage = React.lazy(() => import('./container/Events'));
const RoomsPage = React.lazy(() => import('./container/Rooms'));
const DowntownPage = React.lazy(() => import('./container/Downtown'));
const FortyEightHoursPage = React.lazy(() => import('./container/48Hours'));
const GettingHerePage = React.lazy(() => import('./container/GettingHere'));
const MovieMakerPage = React.lazy(() => import('./container/MovieMaker'));
const GroupContactForm = React.lazy(() => import('./component/ContactForm'));

function App() {
  const [data, setPageData] = useState(null);
  const location = useLocation();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_DOMAIN}/all`)
      .then(response => response.json())
      .then(data => {
        setPageData(data);
      }).catch(error => {
        // @todo handle error
        console.log('Something went wrong');
      })
  }, []);

  if (!data) {
    return <Loader></Loader>
  }

  const { Rooms, Home, ThingsToDo, About, Specials, FortyEightHours, Policies, CoreData, Navigation, PointsOfInterest, GettingHere, AmpRooms, AmpLMS, AmpPackages, AmpEvents, AmpFortyEightHours, MovieMaker, Popup } = data;
  return (
    <Suspense fallback={<Loader></Loader>}>
      <div className="App">
        {Popup && !Popup.Disabled && <PopupControl key={location.pathname} cmsData={Popup}></PopupControl>}
        <Navbar cmsData={Navigation} />
        <Switch>
          <Route exact path="/rooms" component={() => <RoomsPage cmsData={Rooms} Policies={Policies} AmpRooms={AmpRooms} />} />
          <Route exact path="/thingstodo" component={() => <ThingsToDoPage cmsData={ThingsToDo} />} />
          <Route exact path="/downtown" component={() => <DowntownPage cmsData={PointsOfInterest} />} />
          <Route exact path="/about" component={() => <AboutPage cmsData={About} />} />
          <Route exact path="/getting-here" component={() => <GettingHerePage cmsData={GettingHere} />} />
          <Route exact path="/specials" component={() => <SpecialsPage cmsData={Specials} AmpLMS={AmpLMS} AmpPackages={AmpPackages} />} />
          <Route exact path="/events" component={() => <EventsPage cmsData={Specials} AmpEvents={AmpEvents} />} />
          <Route exact path="/48hours" component={() => <FortyEightHoursPage cmsData={FortyEightHours} AmpFortyEightHours={AmpFortyEightHours} />} />
          <Route exact path="/contact" component={() => <GroupContactForm />} />
          <Route exact path="/movie-maker" component={() => <MovieMakerPage cmsData={MovieMaker} />} />
          <Route exact path="/" component={() => <HomePage cmsData={Home} roomsCmsData={Rooms} coreData={CoreData} />} />
          <Route path="*" render={() => { return <Redirect to="/" ></Redirect> }}></Route>
        </Switch>
        <Footer cmsData={{ Policies, CoreData }} />
        <FavouriteManager CoreData={CoreData} Rooms={AmpRooms} LMS={AmpLMS} FortyEightHours={AmpFortyEightHours} Packages={AmpPackages} />
      </div>
    </Suspense>
  );
}

export default App;
