import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown'
import { useForm } from 'react-hook-form';
import { useLocation } from "react-router-dom";

import { useFavouriteContext, actions } from 'context/favourite';

import AmpEmailAPI from 'api/AmpEmailAPI';
import Modal from 'component/Modal';
import FrameL, { Img } from 'component/FrameL';
import CredentialForm from './CredentialForm';
import SharingForm from './SharingForm';

import { ReactComponent as SharePlane } from 'assets/amp-share-plane.svg';
import AMPHeart from 'assets/amp-heart.svg';
import AMPHeartTransparent from 'assets/amp-heart-transparent.svg';
import './style.css';

const FavouriteManager = ({ CoreData, Rooms = [], Packages = [], LMS = [], FortyEightHours = [] }) => {
    const { PhoneLocal, AmpInitialPopup } = CoreData;
    const [isOpen, setIsOpen] = useState(false);
    const [hasInitialAmpPopup, setHasInitialAmpPopup] = useState(localStorage.getItem('initialAmpPopup') !== "true");
    const { dispatch, state } = useFavouriteContext();
    const location = useLocation();

    useEffect(() => {
        const emailAddress = (localStorage.getItem('emailAddress'));
        const firstName = (localStorage.getItem('firstName'));
        const lastName = localStorage.getItem('lastName');

        dispatch(actions.updateUser(emailAddress, firstName, lastName));
    }, []);

    const { user, isPrompting, favourites, sharing } = state;

    const hasUser = !!user.emailAddress;

    useEffect(() => {
        if (hasUser) {
            AmpEmailAPI.getSavedFavorites(user.emailAddress).then((favourites = {}) => {
                dispatch(actions.initializeFavourite(favourites));
            });
        }
    }, [user.emailAddress]);
    const { favouriteRooms, favouriteLMS, favouritePackages, favourite48Hours } = favourites;

    useForm();

    return (<>
        <button onClick={() => {
            if (!hasUser) {
                dispatch(actions.triggerPrompt(true));
            } else {
                setIsOpen(true);
            }
        }} className="favorite__tab">
            <Img src={AMPHeartTransparent} isExternal={false} alt="My Favorites" />
        </button>

        <Modal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            isAnimateBottom={true}
            innerClasses="mb-0 bg-pink-100">
            <div className="absolute w-full top-0 flex z-10">
                <Img className="w-40 mx-auto favorite__panel-logo" isExternal={false} src={AMPHeart} />
            </div>
            <div className="overflow-auto mt-12 favorite__panel">
                <stack-l space="var(--s3)" class="p-4 text-center mx-auto xl:container">
                    <div>
                        <h3>Hey there,</h3>
                        <p className="text-lg">It's your trip and we want to make it special. Your favs can be forwarded to friends too. Just click the forward icon.</p>
                    </div>
                    {Object.keys(favouriteLMS).length !== 0 && <stack-l space="var(--s2)">
                        <div>
                            <h3 className="text-center text-accent">Last Minute Specials</h3>
                            <p className="text-lg">Call {PhoneLocal} to book these phone-only deals. And, yes, you can even make an offer too.</p>
                        </div>
                        <reel-l itemwidth="250px" class="reel--center">
                            {LMS.filter(({ id }) => favouriteLMS[id]).map(({ photo, id, name }) => {
                                return <div key={id}>
                                    <stack-l class="relative" space="0px">
                                        <FrameL ratio="4:5" Image={photo}></FrameL>
                                        <div className="bg-red-500 p-4 h-full">
                                            <h3 className="text-white text-center">{name}</h3>
                                        </div>
                                        <div className="absolute text-white top-0 right-0 m-2">
                                            <SharePlane
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    dispatch(actions.shareLMS(id))
                                                }}
                                                className="icon" />
                                        </div>
                                    </stack-l>
                                    <button
                                        class="mt-4 text-2xl w-full flex items-center justify-center"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            dispatch(actions.shareLMS(id))
                                        }}>Share <SharePlane className="ml-2 icon" /></button>
                                </div>
                            })}
                            <div className="flex flex-col items-center justify-center bg-red-500 text-white mb-13 p-4">
                                <h3>Private Sale</h3>
                                <p>Love what you see? Call to grab it before someone else gets it. Or make an offer via email.</p>
                            </div>
                        </reel-l>
                    </stack-l>}
                    {Object.keys(favouriteRooms).length !== 0 &&
                        <stack-l space="var(--s2)">
                            <h3 className="text-center text-accent">Rooms</h3>
                            <reel-l itemwidth="250px" class="reel--center">
                                {Rooms.filter(({ id }) => favouriteRooms[id]).map(({ carousel, id, name }) => {
                                    return <div key={id}>
                                        <stack-l class="relative" space="0px">
                                            <FrameL ratio="4:3" Image={carousel[0]}></FrameL>
                                            <div className="bg-red-500 p-4 h-full">
                                                <h3 className="text-white text-center">{name}</h3>
                                            </div>
                                            <div className="absolute text-white top-0 right-0 m-2">
                                                <SharePlane
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        dispatch(actions.shareRoom(id))
                                                    }}
                                                    className="icon" />
                                            </div>
                                        </stack-l>
                                        <button
                                            class="mt-4 text-2xl w-full flex items-center justify-center"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                dispatch(actions.shareRoom(id))
                                            }}>Share <SharePlane className="ml-2 icon" /></button>
                                    </div>
                                })
                                }
                            </reel-l>
                        </stack-l>
                    }

                    {Object.keys(favouritePackages).length !== 0 &&
                        <stack-l space="var(--s2)">
                            <h3 className="text-center text-accent">Packages</h3>
                            <reel-l itemwidth="250px" class="reel--center">
                                {Packages.filter(({ id }) => favouritePackages[id]).map(({ photo, id, name }) => {
                                    return <div key={id}>
                                        <stack-l class="relative" space="0px">
                                            <FrameL ratio="4:5" Image={photo}></FrameL>
                                            <div className="bg-red-500 p-4 h-full">
                                                <h3 className="text-white text-center">{name}</h3>
                                            </div>
                                            <div className="absolute text-white top-0 right-0 m-2">
                                                <SharePlane
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        dispatch(actions.sharePackage(id))
                                                    }}
                                                    className="icon" />
                                            </div>
                                        </stack-l>
                                        <button
                                            class="mt-4 text-2xl w-full flex items-center justify-center"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                dispatch(actions.sharePackage(id))
                                            }}>Share <SharePlane className="ml-2 icon" /></button>
                                    </div>
                                })
                                }
                            </reel-l>
                        </stack-l>
                    }

                    {Object.keys(favourite48Hours).length !== 0 &&
                        <stack-l space="var(--s2)">
                            <h3 className="text-center text-accent">48 Hours</h3>
                            <reel-l itemwidth="175px" class="reel--center">
                                {FortyEightHours.filter(({ id }) => favourite48Hours[id]).map(({ photo, id, name }) => {
                                    return <div key={id}>
                                        <stack-l class="relative" space="0px">
                                            <FrameL ratio="3:4" Image={photo}></FrameL>
                                            <div className="bg-red-500 p-4 h-full">
                                                <h5 className="text-white text-center">{name}</h5>
                                            </div>
                                            <div className="absolute text-white top-0 right-0 m-2">
                                                <SharePlane
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        dispatch(actions.share48Hours(id))
                                                    }}
                                                    className="icon" />
                                            </div>
                                        </stack-l>
                                        <button
                                            class="mt-4 text-2xl w-full flex items-center justify-center"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                dispatch(actions.share48Hours(id))
                                            }}>Share <SharePlane className="ml-2 icon" /></button>
                                    </div>
                                })
                                }
                            </reel-l>
                        </stack-l>
                    }
                </stack-l>
            </div>
        </Modal>

        <Modal isOpen={isPrompting} onClose={() => dispatch(actions.triggerPrompt(false))}>
            <stack-l class={`max-w-4xl m-4 sm:mx-auto relative p-4 sm:p-8 bg-white rounded-sm`}>
                <h3 className="text-center">Save Your Favorites & Unlock Private Deals</h3>
                <p className="text-center">Add your name and email to save favorites and to be added to Last Minute Special email alerts. </p>
                <CredentialForm onSubmit={(data) => {
                    const { emailAddress, firstName, lastName } = data;
                    localStorage.setItem('emailAddress', emailAddress);
                    localStorage.setItem('firstName', firstName);
                    localStorage.setItem('lastName', lastName);
                    dispatch(actions.updateUser(emailAddress, firstName, lastName));
                    dispatch(actions.triggerPrompt(false));
                }} />
            </stack-l>
        </Modal>

        <Modal isOpen={sharing.isSharing} onClose={() => dispatch(actions.cleanupShare())}>
            <stack-l class={`max-w-4xl m-4 sm:mx-auto relative p-4 sm:p-8 bg-white rounded-sm items-center`}>
                <SharingForm key={sharing.contentType + sharing.id} Rooms={Rooms} LMS={LMS} Packages={Packages} FortyEightHours={FortyEightHours} />
            </stack-l>
        </Modal>

        <Modal isOpen={hasInitialAmpPopup &&
            (location.pathname === '/rooms' || location.pathname === '/specials' || location.pathname === '/48hours')}
            onClose={() => {
                setHasInitialAmpPopup(false);
                localStorage.setItem('initialAmpPopup', "true");
            }}>
            <div class="max-w-2xl m-4 sm:mx-auto relative p-4 sm:p-8 bg-pink-100 rounded-sm text-center">
                <div className="absolute w-full top-0 left-0 flex z-10">
                    <Img className="w-40 mx-auto favorite__panel-logo" isExternal={false} src={AMPHeart} />
                </div>
                <ReactMarkdown className="mt-16 text-2xl">{AmpInitialPopup}</ReactMarkdown>
            </div>
        </Modal>
    </>
    );
}

export default FavouriteManager;