// Action Types
const SAVE_FAVOURITE = "SAVE_FAVOURITE";
const UNSAVE_FAVOURITE = "UNSAVE_FAVOURITE";
const INITIALIZE_FAVOURITE = "INITIALIZE_FAVOURITE";
const UPDATE_USER = "UPDATE_USER";
const TRIGGER_PROMPT = "TRIGGER_PROMPT";
const SHARE_FAVOURITE = "SHARE_FAVOURITE";
const CLEANUP_SHARE = "CLEANUP_SHARE";

export const actionTypes = {
    SAVE_FAVOURITE,
    UNSAVE_FAVOURITE,
    INITIALIZE_FAVOURITE,
    UPDATE_USER,
    TRIGGER_PROMPT,
    SHARE_FAVOURITE,
    CLEANUP_SHARE
}

// Actions
function saveFavourite(contentType, id) {
    return { type: SAVE_FAVOURITE, id, contentType };
}

function unsaveFavourite(contentType, id) {
    return { type: UNSAVE_FAVOURITE, id, contentType };
}

function shareFavourite(contentType, id) {
    return { type: SHARE_FAVOURITE, id, contentType };
}

export function updateUser(emailAddress, firstName, lastName) {
    return { type: UPDATE_USER, emailAddress, firstName, lastName };
}

export function triggerPrompt(isOpen) {
    return { type: TRIGGER_PROMPT, isOpen };
}

export function initializeFavourite(favourites) {
    return { type: INITIALIZE_FAVOURITE, favourites };
}

// SAVE

export function saveRoom(id) {
    return saveFavourite("favouriteRooms", id);
}

export function saveLMS(id) {
    return saveFavourite("favouriteLMS", id);
}

export function savePackake(id) {
    return saveFavourite("favouritePackages", id);
}

export function save48Hours(id) {
    return saveFavourite("favourite48Hours", id);
}

// UNSAVE
export function unsaveRoom(id) {
    return unsaveFavourite("favouriteRooms", id);
}

export function unsaveLMS(id) {
    return unsaveFavourite("favouriteLMS", id);
}

export function unsavePackake(id) {
    return unsaveFavourite("favouritePackages", id);
}

export function unsave48Hours(id) {
    return unsaveFavourite("favourite48Hours", id);
}

// SHARE

export function shareRoom(id) {
    return shareFavourite("Rooms", id);
}

export function shareLMS(id) {
    return shareFavourite("LMS", id);
}

export function sharePackage(id) {
    return shareFavourite("Packages", id);
}

export function share48Hours(id) {
    return shareFavourite("48Hours", id);
}

export function shareMovies(url) {
    return shareFavourite("Movies", url);
}

export function cleanupShare() {
    return { type: CLEANUP_SHARE };
}