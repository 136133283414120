import { actionTypes } from './actions';

export const initialState = {
    user: {
        emailAddress: null,
        firstName: null,
        lastName: null,
    },
    favourites: {
        favouriteRooms: {},
        favouriteLMS: {},
        favouritePackages: {},
        favourite48Hours: {},
    },
    sharing: {
        isSharing: false,
        contentType: null,
        id: null
    },
    isPrompting: false
};

export default function(state, action) {
    switch (action.type) {
        case actionTypes.SAVE_FAVOURITE: {
            const hasUser = !!state.user.emailAddress;

            if (!hasUser) {
                return {...state, isPrompting: true };
            }

            const { favourites } = state;
            const savedHash = favourites[action.contentType];
            const newSavedHash = { ...savedHash, [action. id]: true };
            const newFavourites = { ...favourites, [action.contentType]: newSavedHash }
            return {...state, favourites: newFavourites };
        } case actionTypes.UNSAVE_FAVOURITE: {
            const { favourites } = state;
            const savedHash = favourites[action.contentType];
            delete savedHash[action.id]
            // const newSavedHash = { ...savedHash, [action.id]: false };
            const newFavourites = { ...favourites, [action.contentType]: savedHash }
            return {...state, favourites: newFavourites };
        } case actionTypes.SHARE_FAVOURITE: {
            const hasUser = !!state.user.emailAddress;

            if (!hasUser) {
                return {...state, isPrompting: true };
            }
            return {
                ...state, 
                sharing: {
                    isSharing: true,
                    contentType: action.contentType,
                    id: action.id
                }
            };
        } case actionTypes.CLEANUP_SHARE: {
            return {
                ...state, 
                sharing: {
                    isSharing: false,
                    contentType: null,
                    id: null
                }
            };
        } case actionTypes.INITIALIZE_FAVOURITE: {
            return {...state, favourites: action.favourites };
        } case actionTypes.TRIGGER_PROMPT: {
            return {...state, isPrompting: action.isOpen };
        } case actionTypes.UPDATE_USER: {
            return {...state, user: { 
                    emailAddress: action.emailAddress,
                    firstName: action.firstName,
                    lastName: action.lastName,
                }
            };
        } default:
            return state;
    }
}