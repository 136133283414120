import React, { useState, useEffect } from 'react';
import { IoMdMenu, IoMdClose, IoMdCall } from 'react-icons/io';
import { Link, withRouter } from 'react-router-dom';

import moment from 'moment';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import { Img } from 'component/FrameL';
import './style.css';

function DesktopNavbar({ Logo }) {
  const [isOpen, setIsOpen] = useState(true);
  const [bookingStart, setBookingStart] = useState(moment(new Date()));
  const [bookingEnd, setBookingEnd] = useState(moment(new Date()));
  const [bookingFocusedInput, setBookingFocusedInput] = useState(null);

  useEffect(() => {
    const handleScrollClose = () => {
      setIsOpen(false);
    }

    document.addEventListener('scroll', handleScrollClose);

    return () => {
      document.removeEventListener('scroll', handleScrollClose);
    }
  }, [])

  return (<div className="fixed z-20">
    <div className={`fixed top-0 left-0 m-4 p-2 navbar-bg-color ${isOpen ? 'hidden' : 'block'}`} onMouseMove={() => {
      setIsOpen(true);
    }}>
      <IoMdMenu className="text-4xl text-black"></IoMdMenu>
    </div>
    <div className={`p-8 navbar__bg-container ${isOpen ? 'visible' : ''}`}>
      <div className="xl:container navbar__content text-black mx-auto flex justify-around items-center text-xl">
        <Link to="/rooms">
          Rooms
        </Link>
        <Link to="/specials">
          Specials
        </Link>
        <Link to="/downtown">
          Downtown
        </Link>
        <Link to="/movie-maker">
          Video Tour
        </Link>
        <Link to="/">
          <Img className="navbar__logo--width" Image={Logo} />
        </Link>
        <Link to="/48hours">
          48 Hrs Here
        </Link>
        <Link to="/thingstodo">
          Things To Do
        </Link>
        <Link to="/about">
          About
        </Link>
        <Link to="/getting-here">
          Transportation
        </Link>
      </div>
    </div>
    <div className={`fixed mt-4 mr-4 top-0 right-0 flex flex-row z-10`}>
      <DateRangePicker
        startDate={bookingStart}
        startDateId="start"
        endDate={bookingEnd}
        endDateId="end"
        onDatesChange={({ startDate, endDate }) => {
          setBookingStart(startDate);
          setBookingEnd(endDate);
        }}
        focusedInput={bookingFocusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={focusedInput => setBookingFocusedInput(focusedInput)}
      >
      </DateRangePicker>
      <a
        href={`https://hotels.cloudbeds.com/en/reservation/jeCvph?ga_sess_id=72226696.1598128268#checkin=${bookingStart && bookingStart.format('YYYY-M-D')}&checkout=${bookingEnd && bookingEnd.format('YYYY-M-D')}`}
        target="_blank"
        rel="noopener noreferrer"
        className="p-2 px-4 navbar-bg-color text-2xl text-black mr-2 no-underline">
        Book Now
      </a>
    </div>
  </div>
  );
}

function MobileNavbar({ Logo }) {
  const [isOpen, setIsOpen] = useState(false);

  return (<div className="fixed z-20">
    <div className={`fixed mt-4 ml-4 top-0 left-0 p-2 navbar-bg-color`} onClick={() => {
      setIsOpen(true);
    }}>
      <IoMdMenu className="text-4xl text-black"></IoMdMenu>
    </div>
    <div className={`fixed mt-4 mr-4 top-0 right-0 p-2 navbar-bg-color`}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://hotels.cloudbeds.com/en/reservation/jeCvph?ga_sess_id=72226696.1598128268">
        <IoMdCall className="text-4xl text-black" />
      </a>
    </div>
    <div className={`text-black text-accent text-2xl uppercase navbar-bg-color flex-col flex items-center p-8 py-16 mobile-nav__container ${isOpen ? 'visible' : ''}`}>
      <IoMdClose className="absolute top-0 right-0 text-4xl m-8" onClick={() => {
        setIsOpen(false);
      }} />
      <Link to="/" onClick={() => {
        setIsOpen(false);
      }}>
        <Img className="navbar__logo--width" Image={Logo} />
      </Link>
      <Link to="/rooms" onClick={() => {
        setIsOpen(false);
      }}>
        Rooms
      </Link>
      <Link to="/specials" onClick={() => {
        setIsOpen(false);
      }}>
        Specials
      </Link>
      <Link to="/downtown" onClick={() => {
        setIsOpen(false);
      }}>
        Downtown
      </Link>
      <Link to="/movie-maker" onClick={() => {
        setIsOpen(false);
      }}>
        Video Tour
      </Link>
      <Link to="/48hours" onClick={() => {
        setIsOpen(false);
      }}>
        48 Hrs Here
      </Link>
      <Link to="/thingstodo" onClick={() => {
        setIsOpen(false);
      }}>
        Things To Do
      </Link>
      <Link to="/about" onClick={() => {
        setIsOpen(false);
      }}>
        About
      </Link>
      <Link to="/about" onClick={() => {
        setIsOpen(false);
      }}>
        Transportation
      </Link>
    </div>
  </div>
  );
}

const NavBar = ({ cmsData }) => {
  const { Logo, LogoWhite } = cmsData;
  return (
    <React.Fragment>
      <div className="hide-on-lg">
        <DesktopNavbar Logo={Logo} />
      </div>
      <div className="block lg:hidden">
        <MobileNavbar Logo={Logo} />
      </div>
    </React.Fragment>
  );
}

export default withRouter(NavBar);