import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { LazyLoadImage as Img } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { AiFillInstagram, AiFillFacebook } from 'react-icons/ai';

import Modal from 'component/Modal';
import FrameL from 'component/FrameL';

import FooterPattern from "../../assets/footer-pattern.jpg";
import BespokeLogo from "../../assets/bespoke_footer_icon.png";
import BLM from "../../assets/blm.png";
import GayPride from "../../assets/gay_pride.png";
import './style.css';

const Footer = ({ cmsData }) => {
    const { CoreData = {} } = cmsData;
    const { HotelName, HotelAddress1, HotelAddress2, PhoneLocal, Facebook, Instagram, Email } = CoreData;
    return <>
        <div className="relative bg-cover px-4 md:px-8 flex flex-row flex-wrap justify-between text-xl text-white items-center z-0 bg-gray-900">
            <div className="w-1/2 md:w-4/12 text-center md:text-left flex flex-col my-4">
                <span>
                    {HotelAddress1}
                </span>
                <span>
                    {HotelAddress2}
                </span>
                <span className="mt-4">
                    Phone: {PhoneLocal}
                </span>
                <Link to="/contact">
                    Contact Us
                </Link>
            </div>
            <stack-l class="w-1/2 md:w-4/12 text-center items-center my-4">
                <Link className="no-underline" to="/rooms#roomPolicies">
                    Policies
                </Link>
                <div className="flex flex-row items-center text-4xl">
                    <a href={Instagram} target="_blank">
                        <AiFillInstagram></AiFillInstagram>
                    </a>
                </div>
                <cluster-l>
                    <div>
                        <Img src={BLM} className="h-16" alt="BLM" />
                        <Img src={GayPride} className="h-16" alt="Gay Pride" />
                    </div>
                </cluster-l>


            </stack-l>
            <div className="w-full md:w-4/12 flex flex-col my-4">
                <div className="flex flex-col items-center md:items-end">
                    <Img src={BespokeLogo} alt="Bespoke Logo" />
                </div>
            </div>
        </div>
        <div className="relative bg-cover h-8 footer__overlay z-0" style={{ backgroundImage: `url(${FooterPattern})` }}>
        </div>
    </>;
};

export default Footer;
